//
//
//
//
//
//
//
//

import BookingIndex from '@/components/Booking/BookingIndex'

export default {
    name: 'LandingPage',
    components: { BookingIndex }
}
