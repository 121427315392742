//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Collapse } from 'ant-design-vue'
import CollapsePanel from 'ant-design-vue/lib/collapse/CollapsePanel'
import ClinicGroupPanel from './ClinicGroupPanel'

export default {
    name: 'LandingPagePanel',
    components: { CollapsePanel, Collapse, ClinicGroupPanel },
    props: {
        groups: { type: Array, required: true },
        activeKey: { default: '' },
        // row count
        facilityRowCount: { default: 3 },
        facilityTabletRowCount: { default: 2 },
        facilityMobileRowCount: { default: 1 },
    },
}
