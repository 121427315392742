//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import axios from 'axios'
    import LandingPagePanel from '@/components/Booking/LandingPagePanel'
    import { ClinicGroup } from '@/services/ClinicGroup'

    export default {
        name: 'BookingIndex',
        components: { LandingPagePanel },
        props: {
            activeGroups: { default: '' }, // determines which group panels are automatically open
            facilityRowCount: { default: 3 },
            facilityTabletRowCount: { default: 2 },
            facilityMobileRowCount: { default: 1 },
			clientManagerPortal: { default: false }
        },
        data: () => ({
            showTitle: false,
            clinicGroups: [],
        }),
        async fetch () {
            await this.fetchClinicGroups()
        },
        mounted () {
            this.showTitle = this.$route.query.showTitle
        },
        methods: {
            async fetchClinicGroups () {
				let response
				if (this.clientManagerPortal) {
                	response = await this.$api.get('/inventory/client-manager-clinics')
				} else {
					response = await axios.get(process.env.inventoryAPI + '/public/clinic-groups')
				}
                for (const clinicGroup of response.data.data) {
                    this.clinicGroups.push(new ClinicGroup(clinicGroup))
                }
            },
        },
    }
